<template>
	<div id="loyout" class="screen-wrapper">
		<div class="screen" id="screen">
			<!-- 页面头部 -->

			<!-- 页面主体路由 -->
			<div id="expositionMain">
				<transition name="main" mode="out-in">
					<router-view :key="$route.fullPath"></router-view>
				</transition>
			</div>
			<!-- 页面底部 -->
			<img  v-if="isReading" class="shuziren" src="@/assets/images/exposition/humanrobat-1.png" alt="">
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			isReading: false 
		}
	},
	computed: {},
	created(){},
	watch: {},
	methods: {
		//数据大屏自适应函数
		handleScreenAuto(){
			const designDraftWidth = 3840;//设计稿的宽度
			const designDraftHeight = 1590;//设计稿的高度
			//根据屏幕的变化适配的比例
			const scale = document.documentElement.clientWidth / document.documentElement.clientHeight < designDraftWidth / designDraftHeight ?
				(document.documentElement.clientWidth / designDraftWidth) :
				(document.documentElement.clientHeight / designDraftHeight);
			//缩放比例
			
			let scaleY = parseFloat(localStorage.getItem('exposition::scaleY')) || 1
            document.querySelector('#screen').style.transform = `scale(${scale}, ${scale * scaleY}) translate(-50%)`;
		},
		//全屏
		clickFullScreen() {
			const docElm = document.documentElement
			if (docElm.requestFullscreen) {
				docElm.requestFullscreen()
			} else if (docElm.msRequestFullscreen) {
				docElm.msRequestFullscreen()
			} else if (docElm.mozRequestFullScreen) {
				docElm.mozRequestFullScreen()
			} else if (docElm.webkitRequestFullScreen) {
				docElm.webkitRequestFullScreen()
			}
			this.showFullScreen = !this.showFullScreen;
		},
		//点击退出全屏
		clickExit() {
			if (document.exitFullscreen) {
				document.exitFullscreen()
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen()
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen()
			}
			this.showFullScreen = !this.showFullScreen;
		},
		currentTime() {
			if(this.countdownInterval) clearInterval(this.countdownInterval);
			this.countdownInterval = setInterval(this.getDate, 60);
		},
		getDate() {
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let week = new Date().getDay();
			let hh = new Date().getHours();
			let mf =
				new Date().getMinutes() < 10 ?
				"0" + new Date().getMinutes() :
				new Date().getMinutes();
			if (week == 1) {
				this.nowWeek = "星期一";
			} else if (week == 2) {
				this.nowWeek = "星期二";
			} else if (week == 3) {
				this.nowWeek = "星期三";
			} else if (week == 4) {
				this.nowWeek = "星期四";
			} else if (week == 5) {
				this.nowWeek = "星期五";
			} else if (week == 6) {
				this.nowWeek = "星期六";
			} else {
				this.nowWeek = "星期日";
			}
			_this.nowTime = hh + ":" + mf;
			// _this.nowDate = yy + "年" + mm + "月" + dd + "日";
			_this.nowDate = yy + "-" + mm + "-" + dd + "-";
		},
	},
	filters: {
	},
	mounted() {
		console.log('00000008888888855555555555555555555555')
		console.log(this.$route.path)
		console.log(sessionStorage.getItem('fromScreen'))
		if (sessionStorage.getItem('fromScreen') == 2 && this.$route.path !== '/chonghuan-monitor') {
			this.$bus.$on('readingFn', (data) => {
				this.isReading = data.isReading
			})
		}
		let that = this
		// 浏览器 title
		document.title = '铁塔数字能源充换电一体化'

		that.currentTime()
		//初始化自适应  ----在刚显示的时候就开始适配一次
		that.handleScreenAuto();
		//绑定自适应函数   ---防止浏览器栏变化后不再适配
		window.onresize = (function(){
			that.handleScreenAuto()
		});
		let { query } = this.$route
		let { scaleY } = query
		scaleY ? localStorage.setItem('exposition::scaleY', scaleY) : ''

	},
	// 销毁定时器
	beforeDestroy: function() {
		this.$bus.$off('readingFn')
		// 在Vue实例销毁前，清除时间定时器
		clearInterval(this.countdownInterval)
		// 数字人播报
		clearInterval(this.textInterval)
		this.robatMP3 = null
		// if (this.getDate) {
		// 	clearInterval(this.getDate); // 在Vue实例销毁前，清除时间定时器
		// }
	},
}
</script>

<style lang="scss" scope>
.screen-wrapper {
	height: 100%;
	width: 100%;
	.screen {
		overflow: hidden;
		display: inline-block;
		width: 3840px;
		height: 1592px;
		transform-origin: 0 0;
		position: absolute;
		left: 50%;
		// background-color: #000;
		.shuziren{
			position: absolute;
			right: 780px;
			bottom: 0;
			z-index: 15;
		}
	}
}

#expositionMain {
	height: 100%;
	// padding-top: 129px;
	// box-sizing: border-box;
}
</style>